<template>
  <header class="bg-white flex flex-row justify-between flex-shrink m-6 3xl:mx-0">
    <div class="text-left">
      <h1 class="text-3xl font-extrabold mb-1">College Search</h1>
      <p class="text-sm">
        Research academic policies, programs and courses
      </p>
      <a :href="urls.catalogViewer" class="text-xs text-primary-800 hover:text-primary-500 underline" @click="trackQuickSearchClick">Jump
        to Quick Search</a>
    </div>

    <div class="ml-auto">
      <a href="https://degreedata.com" target="_blank" class="flex justify-start items-center">
        <img src="https://degreedata.com/wp-content/uploads/2020/12/degreedata_logo.png" alt="DegreeData Logo"
          class="max-w-40 object-contain" />
      </a>
    </div>

    <!-- <div class="flex-grow max-w-40 text-right">
      <UserMenu />
    </div> -->
  </header>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {
      urls: {
        catalogViewer: process.env.VUE_APP_CATALOG_VIEWER_URL
      },
    };
  },
  methods: {
    trackQuickSearchClick() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'quick_search_jump',
        link: this.urls.catalogViewer,
      });
    },
  },
};
</script>