<template>
  <DataTable tableStyle="min-width: 50rem" table-class="table-colleges" :filterDisplay="filterDisplay"
    v-model:filters="filters" :value="shownColleges" :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
    sortField="institutionName" sortOrder="1" paginator>
    <Column :show-clear-button="false" :show-filter-menu="false" :show-add-button="false" field="institutionName"
      sortable="true" header="Institution" filter-header-class="w-full" dataType="text" style="width: 25ch">
      <template #filter="{ filterModel, filterCallback }">
        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter w-full"
          placeholder="Filter by institution" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ slotProps.data.institutionName }}</span>
      </template>
    </Column>
    <Column field="state" sortable="true" :show-clear-button="false" :show-filter-menu="false" header="State" dataType="text"
      style="width:12ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="states" optionLabel="name"
          optionValue="code" placeholder="State" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ states.find((el) => el.code == slotProps.data.state)?.name }}</span>
      </template>
    </Column>
    <Column field="level" sortable="true" :show-clear-button="false" :show-filter-menu="false" header="Level"
      style="width:12ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="levels" optionLabel="name"
          optionValue="code" placeholder="Level" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ levels.find((el) => el.code == slotProps.data.level)?.name }}</span>
      </template>
    </Column>
    <Column field="control" :show-clear-button="false" :show-filter-menu="false" style="width: 13ch;" sortable="true"
      header="Control">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="control" optionLabel="name"
          optionValue="code" placeholder="Control" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ control?.find((el) => el.code == slotProps.data.control)?.name }}</span>
      </template>
    </Column>
    <Column field="academicAssociation" :show-clear-button="false" :show-filter-menu="false" sortable="true"
      header="Affinity Group" style="width:18ch;">
      <template #filter="{ filterModel, filterCallback }">
        <Dropdown showClear v-model="filterModel.value" @change="filterCallback()" :options="affinityGroup"
          optionLabel="name" optionValue="code" placeholder="Affinity Group" class="w-full md:w-14rem" />
      </template>
      <template #body="slotProps">
        <span class="text-sm">{{ affinityGroup?.find((el) => el.code ==
    slotProps.data.academicAssociation)?.name }}</span>
      </template>
    </Column>
    <Column header="Record">
      <template #body="slotProps">
        <Dropdown 
          :options="catalogs[slotProps.data.id]" 
          optionLabel="edition"
          v-model="selectedCatalog" 
          @change="trackCatalogSelection(slotProps.data)"
          optionValue="id" 
          placeholder="Choose the catalog" 
          variant="link-list" 
          class="w-full md:w-14rem"
        >
          <template #option="slotProps">
            <a
              :href="slotProps.option.format === 'PDF'
                ? `${urls.catalogViewer}/?catalogId=${slotProps.option.id}`
                : slotProps.option.catalogUrl"
              :target="slotProps.option.format === 'PDF' ? '_blank' : '_blank'"
              class="py-3 px-5 block"
            >
              {{ slotProps.option.edition }}
            </a>
          </template>
        </Dropdown>
      </template>
    </Column>
    <template #empty>
      <p class="text-left text-sm font-bold py-6">
        <template v-if="hasFilters">
          No results found
        </template>
      </p>
    </template>
  </DataTable>
</template>

<script>
import tailwindConfig from '@/../tailwind.config';
import { useWindowSize } from '@vueuse/core'
const screensList = Object.entries(tailwindConfig.theme.screens);

const { width } = useWindowSize();

const collegeCacheKey = 'college-search--colleges-cache';
const catalogCacheKey = (institutionId) => `college-search--catalog-cache--${institutionId}`;
export default {
  name: "CollegeTable",

  created() {
    this?.onInit();
  },

  data() {
    return {
      width,
      urls: {
        catalogViewer: process.env.VUE_APP_CATALOG_VIEWER_URL
      },
      catalogs: {},
      colleges: [],
      originalColleges: [],
      filters: {
        institutionName: { value: null, matchMode: 'contains' },
        state: { value: null, matchMode: 'equals' },
        level: { value: null, matchMode: 'equals' },
        control: { value: null, matchMode: 'equals' },
        academicAssociation: { value: null, matchMode: 'equals' },
      },
      affinityGroup: [
        {
          code: 'HACU',
          name: 'Hispanic Serving'
        },
        {
          code: 'HBCU',
          name: 'Historically Black College or University',
        },
        {
          code: 'MIL-VET',
          name: 'Military and veteran service.',
        },
        {
          code: 'HBCU, HACU',
          name: 'Historically Black College or University / Hispanic Serving',
        },
        {
          code: 'HBCU, MIL-VET',
          name: 'Historically Black College or University, Military and veteran service.',
        },
        {
          code: 'HACU, MIL-VET',
          name: 'Hispanic Serving, Military and veteran service.'
        },

      ],
      control: [
        // Populate this with state names and codes
        { name: 'Public', code: '1' },
        { name: 'Private non-profit', code: '2' },
        { name: 'Unknown - 3', code: '3' },
      ],
      levels: [
        // Populate this with state names and codes
        { name: '2-year', code: '2-year' },
        { name: '4-year', code: '4-year' },
        { name: 'Less than 2-year', code: 'less t' },
      ],
      states: [
        // Populate this with state names and codes
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' }
      ],
    };
  },
  computed: {
    breakpoint() {
      let bp = screensList[0][0];
      for (const [key, value] of screensList) {
        if (this.width > parseInt(value)) {
          bp = key;
        } else {
          break;
        }
      }

      return bp;
    },
    filterDisplay() {
      return this.breakpoint === 'sm' ? 'menu' : 'row';
    },
    hasFilters() {
      for (const [, state] of Object.entries(this.filters)) {
        if (state.value) return true;
      }
      return false;
    },
    shownColleges() {
      // If no filters are applied, return an empty array
      if (!this.hasFilters) {
        return [];
      }

      // Filter colleges based on multiple conditions
      return this.colleges.filter(college => {
        // Ensure the college has catalogs
        const hasCatalogs = this.catalogs[college.id] &&
            this.catalogs[college.id].length > 0

        // Apply state filter
        const hasMatchingState = !this.filters.state.value ||
            college.state === this.filters.state.value;

        // Apply other optional filters
        const hasMatchingLevel = !this.filters.level.value ||
            college.level === this.filters.level.value;

        const hasMatchingControl = !this.filters.control.value ||
            college.control === this.filters.control.value;

        const hasMatchingInstitutionName = !this.filters.institutionName.value ||
            college.institutionName.toLowerCase().includes(
                this.filters.institutionName.value.toLowerCase()
            );

        const hasMatchingAcademicAssociation = !this.filters.academicAssociation.value ||
            college.academicAssociation === this.filters.academicAssociation.value;

        // Combine all conditions
        return hasCatalogs && (
            hasMatchingState ||
            hasMatchingLevel ||
            hasMatchingControl ||
            hasMatchingInstitutionName ||
            hasMatchingAcademicAssociation
        );
      });
    },
  },
  watch: {
    'filters.state.value'(newState) {
      if (newState) {
        this.fetchStateCollegesWithCatalogs(newState);

        // Push event to the dataLayer
        window.dataLayer.push({
          event: 'filter_by_state',
          filter: {
            type: 'state',
            value: newState,
          },
        });

      } else {
        this.colleges = this.originalColleges;
        this.catalogs = {};
      }
    },

    // Level Filter
    'filters.level.value'(newLevel) {
      if (newLevel) {
        window.dataLayer.push({
          event: 'filter_by_level',
          filter: {
            type: 'level',
            value: newLevel,
          },
        });
      }
    },

    // Control Filter
    'filters.control.value'(newControl) {
      if (newControl) {
        window.dataLayer.push({
          event: 'filter_by_control',
          filter: {
            type: 'control',
            value: newControl,
          },
        });
      }
    },

    // Affinity Group Filter
    'filters.academicAssociation.value'(newAffinityGroup) {
      if (newAffinityGroup) {
        window.dataLayer.push({
          event: 'filter_by_affinity_group',
          filter: {
            type: 'affinity_group',
            value: newAffinityGroup,
          },
        });
      }
    },

  },
  methods: {

    trackCatalogSelection(college) {
      const selectedCatalog = this.selectedCatalog;
      if (!selectedCatalog) return; // Prevent tracking if no catalog is selected

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'catalog_selected',
        catalog: {
          institutionName: college.institutionName,
          institutionId: college.id,
          catalogId: selectedCatalog,
          edition: this.catalogs[college.id]?.find(c => c.id === selectedCatalog)?.edition || null,
        },
      });
    },

    async fetchStateCollegesWithCatalogs(state) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/institutions/state?id=${state}`);
        const stateColleges = await response.json();

        const collegesWithCatalogs = [];
        for (const college of stateColleges) {
          const catalogs = await this.getCatalogs(college.id);

          if (catalogs.length > 0) {
            this.catalogs[college.id] = catalogs;
            collegesWithCatalogs.push(college);
          }
        }

        this.colleges = collegesWithCatalogs;
      } catch (error) {
        console.error('Error fetching state institutions:', error);
        this.colleges = [];
        this.catalogs = {};
      }
    },

    async getCatalogs(institutionId) {
      try {
        const cachedCatalogs = JSON.parse(localStorage.getItem(catalogCacheKey(institutionId)) ?? '[]');
        // console.log('cached catalog.', cachedCatalogs)

        if (cachedCatalogs && Array.isArray(cachedCatalogs) && cachedCatalogs.length) {
          return cachedCatalogs;
        }
      } catch (error) {
        console.error('Error fetching cached colleges:', error);
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs/institution?id=${institutionId}`);
        const json = await response.json();
        const data = json.sort((a, b) => parseInt(b.edition) - parseInt(a.edition));

        localStorage.setItem(catalogCacheKey(institutionId), JSON.stringify(data));

        // console.log('not cached.', data)
        return data;
      } catch (error) {
        console.error('Error fetching catalog:', error);

        return [];
      }
    },
    async getColleges() {
      try {
        const cachedColleges = JSON.parse(localStorage.getItem(collegeCacheKey) ?? '[]');

        if (cachedColleges && Array.isArray(cachedColleges) && cachedColleges.length) {
          return cachedColleges;
        }
      } catch (error) {
        console.error('Error fetching cached colleges:', error);
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/institutions/all`);
        let data = await response.json();

        localStorage.setItem(collegeCacheKey, JSON.stringify(data));

        // console.log('not cached.', data)
        return data;
      } catch (error) {
        console.error('Error fetching colleges:', error);

        return [];
      }
    },

    async onClickCatalogs(institutionId) {
      const catalogs = await this.getCatalogs(institutionId);
      this.catalogs[institutionId] = catalogs;
    },

    async onInit() {
      const colleges = await this.getColleges();
      this.colleges = colleges;
      this.originalColleges = colleges;
    },
  },
};
</script>
